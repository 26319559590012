
import React, { useState, useEffect, useContext,useMemo,useCallback } from "react";
import { GlobalStateContext } from "../UseContextComponents/GlobalStateProvider";
import Select from 'react-select';
import useGet from "../../Hooks/UseGet";
import UsePost from "../../Hooks/UsePost";
import {  showPopup } from "../../Components/Notification";
import Payment from "../../Hooks/Payment";
import PromoCodesList from "../Components/PromoCodesList";
import { DISCOUNT_TYPES } from "../Components/Enum";
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#22c55e' : '#d1d5db',
        outline: 'none',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#22c55e' : '#d1d5db'
        },
        borderWidth: '2px',
        paddingTop: '0.8rem',
        paddingBottom: '0.8rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        borderRadius: '0.375rem'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#000000'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#d1d5db'
    })
};

const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

const validatePhone = (phone) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phone);
};
const ApplyDiscount=(promocode,amount)=>{
   if(DISCOUNT_TYPES.FLAT == promocode.discount_type){
     return amount - promocode.discount_value ;
   }else if(DISCOUNT_TYPES.PERCENTAGE == promocode.discount_type){
    return amount - amount*promocode.discount_value/100;
   }else{
    let discount = amount*promocode.discount_value/100;
    if(discount<promocode.max_discount){
        return amount -discount;
    }
    return amount-promocode.max_discount;
   }
}
const Register = () => {
    const { showRegisterForm, closeRegisterForm, bootcampId, setBootcampId } = useContext(GlobalStateContext);
    const { fetchData, data } = useGet(`/data/bootcampOption`);
    const { post: checkRegister } = UsePost(`/data/registerCheck`);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bootcamp, setBootCamp] = useState(null);
    const [errors, setErrors] = useState({});
    const [amount,setAmount] = useState(0);
    const [discount,setDiscount] = useState(0)
    const [promocode,setPromode] = useState({code:'',id:0});
    const [promocodeSelector,setPromocodeSelector] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message,setMessage] = useState({});
    useEffect(()=>{
       if(promocode.code !=""){
        let amountDiscounted =ApplyDiscount(promocode,amount);
        amountDiscounted = amountDiscounted<0?0:amountDiscounted;
        
        setDiscount(Math.round(amount - amountDiscounted));
        setMessage({discount:`you have saved ₹${Math.round(amount - amountDiscounted)}`})


       }else
       {
        setMessage({});
        setDiscount(0);
       }
    },[promocode])
    const options = useMemo(() => {
        if (data) {
          return data.map((i) => ({
            label: i.title,
            value: i.id,
            amount: i.amount,
          }));
        }
        return [];
      }, [data]);
    useEffect(() => {
        fetchData();
    }, [showRegisterForm]);

    useEffect(() => {
        if (bootcampId !== 0 && options.length > 0) {
          const selectedBootcamp = options.find((x) => x.value === bootcampId);
          setBootCamp(selectedBootcamp);
          setAmount(selectedBootcamp?.amount || 0);
        }
      }, [bootcampId, options]);
   

  

    const handleRegister = async (e) => {
        e.preventDefault();
        const newErrors = {};

        if (!validateEmail(email)) {
            newErrors.email = "Invalid email address";
        }
        if (!validatePhone(phone)) {
            newErrors.phone = "Invalid phone number";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                setIsLoading(true);
                const responseData = await checkRegister({
                    email,
                    bootcampId: bootcamp.value
                });
                if (responseData.success) {
                    
                    Payment({
                        name,
                        email,
                        phone,
                        bootcampId: bootcamp.value,
                        title:bootcamp.label,
                        promocodeId:promocode.id
                    }, (messages) => {
                       
                        showPopup({success:true,message:messages.data.message})
                        resetForm();
                        closeRegisterForm();
                        setIsLoading(false);
                    }, (errorMessage) => {
                        showPopup({success:false,message:errorMessage})
                        setIsLoading(false);
                        
                    });
                } else {
                    newErrors.email = "Email address already registered";
                    setErrors(newErrors);
                    setIsLoading(false);
                }
            } catch (error) {
                setErrors({ general: 'Registration failed' });
                setIsLoading(false);
            }
        }
    };
   const openPromoCodeList=useCallback(() => {
       const error = {}
     
       if(bootcamp == null || bootcamp == undefined || bootcamp?.value == 0 || bootcamp==""){
          error.promocode = "bootcamp must be selected "
          setErrors(error)
          return
       }
       if(!validateEmail(email)){
           error.promocode = "must have a valid email "
           setErrors(error)
           return;
        
       }
       setErrors({})
       setPromocodeSelector(true);
   },[email,bootcamp])
    const resetForm = useCallback(() =>  {
        setName('');
        setEmail('');
        setPhone('');
        setBootCamp('');
        setErrors({});
        setBootcampId(0);
        setAmount(0)
        setPromode({code:"",id:0})
    })

    return (
        <>
           
            {
                showRegisterForm ? (
                    <div className="fixed top-0 left-0 z-20 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
                        <form onSubmit={handleRegister} className="bg-white w-full h-max sm:w-[580px]  rounded-lg p-6 font-redhat flex flex-col space-y-4 slide-in-left">
                            <span className="text-[#545454] font-semibold text-base">REGISTER FORM</span>
                            <label className="relative">
                                <input
                                    name="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    className="w-full py-4 px-4 border-2 border-gray-300 rounded-lg outline-none focus:border-green-500 transition-colors duration-200"
                                />
                                
                                <span className={`text-xl absolute text-gray-300 left-0 top-4 mx-6 px-2 transition duration-200 name
                                     ${name !== '' ? "bg-white transform -translate-y-7 -translate-x-4 scale-75" : ""}`}>
                                    Name
                                </span>
                            </label>
                            <label className="relative">
                                <input
                                    name="name"
                                    type="email"
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full py-4 px-4 border-2 border-gray-300 rounded-lg outline-none focus:border-green-500 transition-colors duration-200"
                                />
                                <span className={`text-xl absolute text-gray-300 left-0 top-4 mx-6 px-2 transition duration-200 name ${email !== '' ? "bg-white transform -translate-y-7 -translate-x-4 scale-75" : ""}`}>
                                    Email
                                </span>
                                {errors.email && <p className="text-red-500">{errors.email}</p>}
                            </label>
                            <label className="relative">
                                <input
                                    name="name"
                                    type="text"
                                    value={phone}
                                    required
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="w-full py-4 px-4 border-2 border-gray-300 rounded-lg outline-none focus:border-green-500 transition-colors duration-200"
                                />
                                <span className={`text-xl absolute text-gray-300 left-0 top-4 mx-6 px-2 transition duration-200 name ${phone !== '' ? "bg-white transform -translate-y-7 -translate-x-4 scale-75" : ""}`}>
                                    Phone
                                </span>
                                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                            </label>
                            <label className="relative">    
                                <Select
                                    placeholder="Select a bootcamp"
                                    value={bootcamp}
                                    onChange={(selectedOption) => {setBootCamp(selectedOption);setAmount(selectedOption.amount);setPromode({code:'',id:0})  }}
                                    options={options}
                                    isDisabled={bootcampId!==0}
                                    required
                                    styles={customStyles}
                                />
                                {errors.bootcamp && <p className="text-red-500">{errors.bootcamp}</p>}
                            </label>
                            <label className="relative">
                          
                                <input
                                    name="name"
                                    type="text"
                                    value={promocode.code}
                                    disabled
                                    placeholder="select the promocode"
                                    className="w-full py-4 px-4 border-2 border-gray-300 rounded-lg outline-none focus:border-green-500 transition-colors duration-200"
                                />
                                { promocode.code == '' ? 
                                <button type="button"
                                  className="w-36 py-1 bg-green-400 text-lg text-white border-2 border-green-400 hover:text-green-400 hover:bg-white rounded-lg absolute right-2 top-3"
                                  onClick={()=>openPromoCodeList()}
                                >
                                    Apply Coupon
                                </button>:<button type="button" onClick={()=>setPromode({code:'',id:0})}
                                  className="w-32 py-1 bg-red-400  text-lg text-white border-2 border-red-400 hover:text-red-400 hover:bg-white rounded-lg absolute right-2 top-3"
                                >
                                    Remove
                                </button>
                                   
                                }
                                 {errors.promocode && <p className="text-red-500">{errors.promocode}</p>}
                                 {message.discount && <p className="text-green-500">{message.discount}</p>}
                            </label>
                            <label className="relative">
                                <input
                                    name="name"
                                    type="text"
                                    value={amount-discount}
                                    disabled
                                    required
                                    className="w-full py-4 px-4 border-2 border-gray-300 rounded-lg outline-none focus:border-green-500 transition-colors duration-200"
                                />
                                <span className={`text-xl absolute text-gray-300 left-0 top-4 mx-6 px-2 transition duration-200 name ${amount !== '' ? "bg-white transform -translate-y-7 -translate-x-4 scale-75" : ""}`}>
                                   Amount
                                </span>
                                
                            </label>
                            <div className="flex space-x-4 font-semibold">
                      <button type="button" onClick={() => { closeRegisterForm(); resetForm(); }} disabled={isLoading} className="w-full py-4 bg-white text-gray-500 hover:bg-gray-100 border-2 border-gray-300 rounded-lg">CANCEL</button>

                      <button className="w-full py-4 bg-line text-white border-2 border-line hover:text-line hover:bg-white rounded-lg" type="submit" disabled={isLoading}>{isLoading ? (
    <p className="flex h-full items-center justify-center">
        <svg
      className="animate-spin h-5 w-5 mr-3"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        fill="none"
      />
      <path
        fill="currentColor"
        d="M4 12a8 8 0 0 1 16 0"
      />
    </svg>
    Processing
    </p>
  ) : (
    'REGISTER'
  )} </button>
                  </div>
                            {errors.payment && <p className="text-red-500">{errors.payment}</p>}
                        </form>
                       { promocodeSelector ? 
                        <PromoCodesList promocodeSelector={promocodeSelector} setPromocodeSelector={setPromocodeSelector} setPromode={setPromode} email={email} bootcampId = {bootcamp?.value}/>:''
                       }
                    </div>
                    
                ) : null
            }

        
        </>
    );  
};

export default Register;
