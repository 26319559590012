import React, { createContext, useState, useContext, useEffect, startTransition } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = () => {
    startTransition(() => {
      setIsAuthenticated(true);
    });
  };

  const logout = () => {
    startTransition(() => {
      setIsAuthenticated(false);
    });
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`/admin`, {
          method: 'GET',
          credentials: 'include',
        });
        
        if (response.ok) {
          
            startTransition(() => {
              setIsAuthenticated(true);
            });
          
        } else {
          startTransition(() => {
            logout();
          });
        }
      } catch (error) {
        startTransition(() => {
          logout();
        });
      }
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
