import React, { useEffect, useState, startTransition } from "react";
import BootcampCards from "../BootcampComponents/BootcampCards";
import CardDetails from "../BootcampComponents/CardDetails";
import useGet from "../../Hooks/UseGet";
import { GlobalStateProvider } from "../UseContextComponents/GlobalStateProvider";
import Register from "../NavComponents/Register";

const ActiveBootcamps = () => {
  const [displayCard, setDisplayCard] = useState(true);
  const [id, setId] = useState(0);
  const { fetchData, data, loading, error } = useGet(`/data/bootcamp`);
  const [d1,setd1] = useState();

  useEffect(() => {
    startTransition(() => {
      fetchData();
    });
  }, [fetchData]);
 

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <GlobalStateProvider>
     
     <div className="px-0 sm:px-6 md:px-12 h-full py-2 sm:py-8 ">
  {displayCard ? (
    <div className="h-full w-full  flex flex-col p-0 sm:p-6  ">
      <p className="text-white  text-2xl sm:text-4xl  font-redhat flex  items-center w-full justify-center  py-4  ">
        <span className="h-12 w-full font-bold text-center  cursor-pointer px-1">Upcoming Sessions</span>
      </p>
     
      <div className="h-full w-full mt-8 p-0 sm:p-4 mb-8">
        <BootcampCards
          data={data}
          setDisplayCard={setDisplayCard}
          setId={setId}
        />
      </div>
    </div>
  ) : (
    <CardDetails setDisplayCard={setDisplayCard} details={data[id]} />
  )}
</div>




      
      <Register/>
    </GlobalStateProvider>
  );
};

export default ActiveBootcamps;
