export const PROMO_TYPES = {
    PARTICULAR_BOOTCAMP: 'for_particular_bootcamp',
    ANYONE: 'anyone',
    PARTICULAR_USERS: 'valid_for_particular_users',
    PARTICULAR_USER_AND_BOOTCAMP:'valid_for_bootcamp_and_user'
  };
  
  export const DISCOUNT_TYPES = {
    FLAT: 'flat',
    PERCENTAGE: 'percentage',
    UPTO_PERCENTAGE: 'upto_and_percentage'
  };

  