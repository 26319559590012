import React, { useEffect, useMemo } from 'react'
import { IoClose } from 'react-icons/io5';
import useGet from '../../Hooks/UseGet';
import { DISCOUNT_TYPES } from './Enum';
const PromoCodesList = ({setPromocodeSelector, setPromode,bootcampId,email}) => {
    const url = useMemo(()=>{ 
       return  `/data/promocodes?bootcampId=${bootcampId}&email=${email}`} ,[bootcampId,email])
    const { data:promocodes = [] } = useGet(url);
    
    
    
  return (
    
    
    <div className='fixed top-0 left-0 z-20 w-full h-full flex justify-center items-center bg-black bg-opacity-50'>
       <div className='px-2 sm:px-0 w-full sm:w-[540px]  h-[400px] min-h-[500px] rounded relative bg-gray-200 overflow-y-auto hide-scrollbar '>
           <div>
            <div className='sticky top-0'>
            <h2 className="text-xl  bg-inherit font-semibold p-2 ml-6">Available Promo Codes</h2>

            <button className='text-red-500 top-2 right-2 absolute text-3xl font-bold' onClick={()=>setPromocodeSelector(false)}><IoClose></IoClose></button>

            </div>
                { promocodes && promocodes.length > 0 ? (
                    <div className="p-0 sm:p-2">
                        {promocodes.map((promocode) => (
                           <div key={promocode.id} onClick={()=>{setPromode(promocode);setPromocodeSelector(false);}} className='flex mx-0s sm:mx-8 my-2 h-[100px] shadow-lg bg-white cursor-pointer'>
                            <div className='w-32 sm:w-[150px] bg-[#00ff60] rounded-l text-black font-bold flex justify-center items-center font-libre-franklin '>{promocode.code}</div>
                            <div className='rounded-r mx-4 my-2 bg-white'>
                                <p className='font-bold'>{getDiscount(promocode)}</p>
                             <p className='text-gray-600'>Use code {promocode.code} and {getDiscount(promocode).toLowerCase()} </p>
                            </div>
                           </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-500">No promo codes available.</p>
                )}
           </div>
            </div>
        
      
    </div>
   
  )
}

const getDiscount = (promocode) => {
    if (promocode.discount_type === DISCOUNT_TYPES.PERCENTAGE) {
      return `Get ${promocode.discount_value}% off`;
    } else if (promocode.discount_type === DISCOUNT_TYPES.FLAT) {
      return `Get a flat discount of ₹${promocode.discount_value}`;
    } else {
      return `Get ${promocode.discount_value}% off, up to ₹${promocode.max_discount}`;
    }
  };

export default PromoCodesList