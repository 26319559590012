import React ,{useState,useEffect}from 'react';
import Carousel from 'react-elastic-carousel';
import { format } from 'date-fns';
import CustomArrow from './CustomArrow';
import '../../CSSFILES/card.css'

const BootcampCards = ({ data = [], setDisplayCard, setId }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM dd, yyyy ");
  };
  const [isPaginationEnabled, setIsPaginationEnabled] = useState(window.innerWidth < 700);

  
  useEffect(() => {
    const handleResize = () => {
      setIsPaginationEnabled(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);

    
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const handleClick = (id) => {
    setDisplayCard(false);
    setId(id);
    
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1},
   
    {width:700,itemsToShow:1,itemsToScroll:1},
    { width: 800, itemsToShow: 2, itemsToScroll: 2 }, // Fixed 2 items per row for mid-size screens
    { width: 1100, itemsToShow: 3, itemsToScroll: 3 } // Fixed 3 items per row for larger screens
  ];

  return (
    <div className="w-full ">
      <Carousel
        breakPoints={breakPoints}
        renderArrow={ !isPaginationEnabled? (props) => <CustomArrow {...props} /> : null}
        pagination={isPaginationEnabled}
        itemPadding={[0, 0]} 
      >
        {data && data.length > 0 ? (
          data.map((camp, index) => (
            <div key={index} className="carousel-item gap-4 overflow-hidden"> 
              <BootcampCard
                image={camp.image_path}
                date={formatDate(camp.start_date)}
                title={camp.title}
                amount={camp.amount}
                onClick={() => handleClick(index)}
              />
            </div>
          ))
        ) : (
          <p className="text-gray-700">No bootcamps available.</p>
        )}
      </Carousel>
    </div>
  );
};

const BootcampCard = ({ image, date, title,amount, onClick }) => (
  <div
  className="w-full w-max-sm  sm:w-[370px] cursor-pointer bg-white p-2 rounded-[30px] h-[340px] sm:h-[350px]" // Set a fixed height for the card
  onClick={onClick}
>
  <div className="h-48 overflow-hidden rounded-t-[30px]">
    <img className="w-full h-full object-cover" src={image} alt={title} />
  </div>
  <div className='p-2 font-redhat h-max justify-between flex'>
    <div className='w-[180px] sm:max-w-[65%]'>
      <p className='mt-2 font-semibold sm:font-bold text-normal sm:text-lg line-clamp-2'>{title}</p>
      <p className='text-sm text-gray-500 mt-2'>{date}</p>
      <p className='text-xl font-bold mt-2'>₹{amount}</p>
    </div>
    <div className='flex items-center sm:w-[35%] w-[120px] justify-center'>
      <button className='border-2 border-line text-normal px-4 py-2 rounded-full transition duration-500 ease-in hover:scale-105'>
        See details
      </button>
    </div>
  </div>
</div>
);

export default BootcampCards;
